import React, { useEffect, useRef, useState } from "react";
import ChessPiecesImg from "@/assets/images/chess/chess-big.png";
import { TopBar } from "@/modules/home";
import Image from "next/image";
import Link from "next/link";
import { useCurrentUser } from "@/contexts/currentUser";

const StartNewGame = () => {
    const ref = useRef(null);

    const { isUserActivated } = useCurrentUser();

    const [visible, setVisible] = useState(true);

    useEffect(() => {
        //@ts-ignore
        if (ref && ref.current && ref.current.clientHeight) {
            //@ts-ignore
            if (ref.current?.clientHeight < 128) {
                setVisible(false);
            } else {
                setVisible(true);
            }
        }
    }, [ref]);

    return (
        <div className="flex w-full flex-grow flex-col items-center overflow-hidden bg-start-game !bg-cover !bg-center !bg-no-repeat">
            <TopBar />
            <div className="flex flex-grow  flex-col items-center justify-center overflow-hidden py-9">
                <div className="relative flex-grow overflow-hidden py-6">
                    <Image
                        ref={ref}
                        src={ChessPiecesImg}
                        alt="chess-pieces"
                        className={`cursor-pointer ${
                            visible ? "block" : "hidden"
                        }`}
                        draggable="false"
                        style={{
                            objectFit: "contain",
                            maxHeight: "100%",
                        }}
                        loading="lazy"
                    />
                </div>
                <div className="mt-2 flex flex-col items-center justify-center gap-y-4">
                    <div className="flex flex-col items-center justify-center">
                        <h1 className="text-center font-medium text-gray-4 font-poppins text-base">
                            {isUserActivated
                                ? "Play chess mini-battles"
                                : "Train your Brain & Earn Cash"}
                        </h1>
                        <p
                            className={`text-center font-bold text-gray-4 font-poppins text-2xl`}
                        >
                            {isUserActivated
                                ? "Win cash in 6 moves"
                                : "In Just 2 Minutes!"}
                        </p>
                    </div>
                    <Link
                        href="/battle-mode"
                        data-testid="start-new-single-player-game-button"
                        className="whitespace-nowrap rounded-md bg-dark-pink-1 px-4 py-3 text-center font-extrabold text-gray-4 shadow-home-banner font-basement-grotesque text-xl"
                    >
                        Play Now : FREE
                    </Link>
                    {/* <CreateRoomModal isSinglePlayerGame={true} tournamentID={`2`}>
                        <button
                            className="mb-4 whitespace-nowrap rounded-md bg-dark-pink-1 p-0 px-6 py-2.5 text-center font-extrabold text-gray-4 shadow-home-banner font-basement-grotesque text-xl"
                        >
                            Resume
                        </button>
                    </CreateRoomModal> */}
                </div>
            </div>
        </div>
    );
};

export default StartNewGame;
