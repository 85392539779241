/* eslint-disable max-lines */
import React, { Fragment, useMemo } from "react";
import BattleModeLogo from "@/assets/images/quick-links/battle.png";
import DailyLeagueLogo from "@/assets/images/quick-links/daily.png";
import ROUTES from "@/constants/routes";
import FriendsModeLogo from "@/assets/images/quick-links/friends.png";
import dayjs from "dayjs";
import LeagueResultLogo from "@/assets/images/quick-links/result.png";
import Image from "next/image";
import Loader from "@/components/Loader";
import { CreateRoomModal } from "@/modules/createRoom";
import Link from "next/link";
import { useGetLatestTournamentQuery } from "@/types/generated-types";
import { useCurrentUser } from "@/contexts/currentUser";
import { toast } from "@/utils";
import { Rupee } from "@/components";

const QuickLinks = () => {
    const { isTournamentEnabled } = useCurrentUser();

    const { data } = useGetLatestTournamentQuery();

    const {
        lastTournamentIDWithRewards,
        startDate,
        endDate,
        tournamentID,
        remainingRetries,
        isRegistered,
    } = useMemo(() => {
        if (data?.tournamentWithRewardsDistributed) {
            const startDate = {
                day: dayjs(
                    data?.tournamentWithRewardsDistributed?.[0]?.start_time ??
                        data?.tournament?.[0]?.start_time
                ).format("D"),
                month: dayjs(
                    data?.tournamentWithRewardsDistributed?.[0]?.start_time ??
                        data?.tournament?.[0]?.start_time
                ).format("MMM"),
            };

            const endDate = {
                day: dayjs(
                    data?.tournamentWithRewardsDistributed?.[0]?.end_time ??
                        data?.tournament?.[0]?.end_time
                ).format("D"),
                month: dayjs(
                    data?.tournamentWithRewardsDistributed?.[0]?.end_time ??
                        data?.tournament?.[0]?.end_time
                ).format("MMM"),
            };

            return {
                lastTournamentIDWithRewards:
                    data?.tournamentWithRewardsDistributed?.[0]?.id,
                startDate,
                endDate,
                remainingRetries:
                    data?.tournament?.[0]?.tournament_participants?.[0]
                        ?.remaining_retries,
                tournamentID: data?.tournament?.[0]?.id,
                isRegistered:
                    data?.tournament?.[0]?.tournament_participants?.length > 0,
            };
        }

        return {
            lastTournamentIDWithRewards: "",
            startDate: {
                day: 1,
                month: "",
            },
            remainingRetries: 10,
            endDate: {
                day: 1,
                month: "",
            },
            tournamentID: "",
            isRegistered: false,
        };
    }, [data]);

    return (
        <div className="z-[1] mx-auto flex w-full flex-grow flex-col items-start justify-start bg-dark-blue-21 px-5 pb-4 font-poppins">
            <h3 className="mb-3 text-left font-semibold text-gray-5 text-base">
                Quick links
            </h3>
            <div className="flex w-full flex-col justify-between gap-5 ">
                <div className="flex w-full flex-row items-stretch gap-5">
                    <Link
                        href={ROUTES.battleMode()}
                        className="relative w-1/2 max-w-180 rounded-lg bg-dark-rose-1 bg-opacity-70"
                    >
                        <div className="p-4 pb-2">
                            <p className="font-extrabold tracking-wider text-gray-4  font-basement-grotesque text-base leading-20">
                                Battle
                                <br />
                                Mode
                            </p>
                        </div>
                        <Image
                            src={BattleModeLogo}
                            alt="battle-mode"
                            className="absolute -top-3 right-3 h-12 w-10"
                        />
                        <div className="w-full rounded-b-lg bg-pink-600-1 text-center">
                            <p className="font-medium text-dark-black-6 text-xs">
                                Entry Free!
                            </p>
                        </div>
                    </Link>
                    <div className="relative w-1/2 max-w-180 cursor-pointer rounded-lg bg-[#AC0C89B2]">
                        <CreateRoomModal
                            isSinglePlayerGame={false}
                            className="h-full w-full"
                            loader={
                                <div className="flex h-full w-full items-center justify-center bg-[#AC0C89B2]">
                                    <Loader className="!my-0 !h-5 !w-5 border-white" />
                                </div>
                            }
                        >
                            <div className="p-4 pb-2">
                                <p className="font-extrabold tracking-wider text-gray-4 font-basement-grotesque text-base leading-20">
                                    Friends
                                    <br />
                                    Mode
                                </p>
                            </div>
                            <Image
                                src={FriendsModeLogo}
                                alt="battle-mode"
                                className="absolute -top-3 right-3 h-12 w-10"
                            />
                        </CreateRoomModal>
                    </div>
                </div>
                <div className="flex w-full flex-row items-stretch gap-5">
                    <Link
                        data-testid="daily-league-quick-link"
                        href={
                            isTournamentEnabled
                                ? remainingRetries === 0
                                    ? ROUTES.tournamentLeaderboard({
                                          tournamentID,
                                      })
                                    : isRegistered
                                    ? ROUTES.tournamentProgress({
                                          tournamentID,
                                      })
                                    : ROUTES.tournament({
                                          tournamentID,
                                      })
                                : {}
                        }
                        onClick={(e) => {
                            if (!isTournamentEnabled) {
                                e.preventDefault();
                                toast({
                                    type: "info",
                                    message: "Coming Soon!",
                                });
                            }
                        }}
                        className={`relative  w-1/2 max-w-180 rounded-lg bg-[#6F0CACB2] ${
                            isTournamentEnabled ? "" : "disabled opacity-30"
                        } `}
                    >
                        <div className="p-4 pb-2">
                            <p className="font-extrabold tracking-wider text-gray-4 font-basement-grotesque text-base leading-20">
                                Daily
                                <br />
                                League
                            </p>
                        </div>
                        <Image
                            src={DailyLeagueLogo}
                            alt="battle-mode"
                            className="absolute -top-3 right-3 h-12 w-10"
                        />
                        <div className="w-full rounded-b-lg bg-[#9B36DFB2] text-center">
                            <p className="font-medium text-dark-black-6 text-xs">
                                Win <Rupee />
                                500 for FREE
                            </p>
                        </div>
                    </Link>
                    <Link
                        href={
                            lastTournamentIDWithRewards && isTournamentEnabled
                                ? ROUTES.tournamentLeaderboard({
                                      tournamentID: lastTournamentIDWithRewards,
                                  })
                                : {}
                        }
                        onClick={(e) => {
                            if (!isTournamentEnabled) {
                                e.preventDefault();
                                toast({
                                    type: "info",
                                    message: "Coming Soon!",
                                });
                            }
                        }}
                        className={`relative  w-1/2 max-w-180 rounded-lg bg-[#0CA3ACB2] ${
                            lastTournamentIDWithRewards && isTournamentEnabled
                                ? ""
                                : "disabled opacity-30"
                        } `}
                    >
                        <div className="p-4 pb-2">
                            <p className="font-extrabold tracking-wider text-gray-4 font-basement-grotesque text-base leading-20">
                                League
                                <br />
                                Results
                            </p>
                        </div>
                        <Image
                            src={LeagueResultLogo}
                            alt="battle-mode"
                            className="absolute -top-3 right-3 h-12 w-12"
                        />
                        <div className="w-full rounded-b-lg bg-[#3FACB8B2] text-center">
                            <p className="font-medium text-dark-black-6 text-xs">
                                {lastTournamentIDWithRewards ? (
                                    <Fragment>
                                        {startDate.day === endDate.day &&
                                        startDate.month === endDate.month ? (
                                            <Fragment>
                                                {startDate.day}{" "}
                                                {startDate.month}
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                {startDate?.day}
                                                {startDate.month ===
                                                endDate.month
                                                    ? ""
                                                    : ` ${startDate.month}`}
                                                -{endDate.day} {endDate.month}
                                            </Fragment>
                                        )}
                                    </Fragment>
                                ) : (
                                    "-"
                                )}
                            </p>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default QuickLinks;
