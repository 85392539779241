//@ts-nocheck
/* @ts-nocheck eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars */
import * as Types from '../../../types/generated-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@/utils/apolloReactHooks';
const defaultOptions = {} as const;
export type GetLatestTournamentQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetLatestTournamentQuery = { __typename?: 'Query', tournament: Array<{ __typename?: 'tournament', id: any, start_time: any, end_time: any, tournament_participants: Array<{ __typename?: 'tournament_participants', current_level: number, rank?: number | null, remaining_retries: number }> }>, next_tournament: Array<{ __typename?: 'tournament', id: any, start_time: any }>, tournamentWithRewardsDistributed: Array<{ __typename?: 'tournament', id: any, start_time: any, end_time: any }> };


export const GetLatestTournamentDocument = gql`
    query getLatestTournament {
  tournament(
    order_by: {end_time: desc_nulls_last}
    limit: 1
    where: {is_active: {_eq: true}}
  ) {
    id
    start_time
    end_time
    tournament_participants {
      current_level
      rank
      remaining_retries
    }
  }
  next_tournament: tournament(
    order_by: {end_time: desc_nulls_last}
    limit: 1
    where: {is_active: {_eq: false}}
  ) {
    id
    start_time
  }
  tournamentWithRewardsDistributed: tournament(
    order_by: {end_time: desc_nulls_last}
    limit: 1
    where: {is_rewards_distributed: {_eq: true}, is_active: {_eq: false}}
  ) {
    id
    start_time
    end_time
  }
}
    `;

/**
 * __useGetLatestTournamentQuery__
 *
 * To run a query within a React component, call `useGetLatestTournamentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestTournamentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestTournamentQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLatestTournamentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLatestTournamentQuery, GetLatestTournamentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetLatestTournamentQuery, GetLatestTournamentQueryVariables>(GetLatestTournamentDocument, options);
      }
export function useGetLatestTournamentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLatestTournamentQuery, GetLatestTournamentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetLatestTournamentQuery, GetLatestTournamentQueryVariables>(GetLatestTournamentDocument, options);
        }
export type GetLatestTournamentQueryHookResult = ReturnType<typeof useGetLatestTournamentQuery>;
export type GetLatestTournamentLazyQueryHookResult = ReturnType<typeof useGetLatestTournamentLazyQuery>;
export type GetLatestTournamentQueryResult = Apollo.QueryResult<GetLatestTournamentQuery, GetLatestTournamentQueryVariables>;