import React from "react";
import QuestionMarkImg from "@/assets/images/icons/question-mark-white.png";
import WalletImg from "@/assets/images/icons/wallet.png";
import ZiffiLogo from "@/assets/images/ziffi-logo-rm.png";
import { HowToPlay, Menu, Rupee } from "@/components";
import { useCurrentUser } from "@/contexts/currentUser";
import Image from "next/image";
import { useRouter } from "next/router";

const TopBar = () => {
    const router = useRouter();

    const { walletBalance } = useCurrentUser();

    return (
        <div className="sticky top-5 flex  w-full items-end justify-between bg-transparent px-3.5">
            <div className="flex w-1/2 items-center justify-start gap-x-0.5">
                <Menu />
                <Image
                    src={ZiffiLogo}
                    width={41}
                    height={16}
                    alt="ziffi-logo"
                    className="cursor-pointer"
                    onClick={() => router.reload()}
                />
            </div>
            <div className="flex w-1/2 items-stretch justify-end gap-x-2.5">
                <div className="w-fit">
                    <HowToPlay>
                        <Image
                            src={QuestionMarkImg}
                            alt="question-mark"
                            width={28}
                            height={28}
                            draggable={false}
                            className="cursor-pointer"
                            loading="lazy"
                        />
                    </HowToPlay>
                </div>
                <div
                    onClick={() => router.push("/wallet")}
                    className="flex cursor-pointer items-center justify-center rounded-3xl border-[1.5px] border-gray-4 px-3 text-center font-medium text-white font-poppins text-xs"
                >
                    <Image
                        src={WalletImg}
                        alt="wallet"
                        height={16}
                        width={16}
                        className=""
                        loading="lazy"
                    />{" "}
                    &nbsp;
                    <p
                        data-testid="wallet-balance"
                        className="font-medium text-gray-4 font-poppins text-sm"
                    >
                        <Rupee />
                        {walletBalance}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TopBar;
