// @ts-nocheck
import React from "react";
import "swiper/css";
// import { Autoplay, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { PlayWithFriends } from "./components";

const index = () => {
    return (
        <div className="z-[1] mx-auto flex w-full  flex-col items-start justify-start bg-dark-blue-21 px-5 pb-4">
            <h3 className="text-left font-semibold text-gray-5 font-poppins text-base">
                More game modes
            </h3>
            <Swiper
                className="mySwiper mt-3 w-full"
                // loop={true}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                slidesPerView={"auto"}
                spaceBetween={20}
                // modules={[Autoplay, A11y]}
            >
                <SwiperSlide key={1} className="!my-auto !w-full max-w-80/100">
                    <PlayWithFriends />
                </SwiperSlide>
            </Swiper>
        </div>
    );
};

export default index;
