import React from "react";
import { CreateRoomModal } from "@/modules/createRoom";

const PlayWithFriends = () => {
    return (
        <div
            className={`relative flex h-full min-h-35 w-full flex-col rounded-lg bg-challenge-friends py-4 transition-all duration-500 ease-in-out`}
        >
            <div
                className={`pointer-events-none absolute bottom-0 left-0 right-0 top-0 h-full w-full bg-people-playing-chess bg-left-bottom bg-no-repeat`}
                style={{ backgroundSize: "52% auto" }}
            />
            <div className="mx-auto flex h-full w-full max-w-90/100 flex-col items-stretch justify-between gap-y-3">
                <div className="relative flex w-fit flex-col">
                    <p className="font-poppins text-sm font-medium text-white">
                        Ziffi chess
                    </p>
                    <p className="font-basement-grotesque text-base font-extrabold text-orange">
                        Play with friends
                    </p>
                </div>
                <div className="mt-3 flex w-full items-center justify-end">
                    <CreateRoomModal isSinglePlayerGame={false}>
                        <button
                            data-testid="start-new-single-player-game-button"
                            className="whitespace-nowrap rounded-md border-2 border-dark-pink-1 bg-light-grey px-3 py-2 text-center font-basement-grotesque text-sm font-bold text-gray-4"
                        >
                            Play now
                        </button>
                    </CreateRoomModal>
                </div>
            </div>
        </div>
    );
};

export default PlayWithFriends;
