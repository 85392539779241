import React, { useEffect, useMemo, useRef, useState } from "react";
import trophyImg from "@/assets/images/tournament/grand-prize-trophy.png";
import { TopBar } from "@/modules/home";
import { useGetLatestTournamentQuery } from "@/pages/tournament/operations/operations.generated";
import Image from "next/image";
import ROUTES from "@/constants/routes";
import { useRouter } from "next/router";
import Loader from "@/components/Loader";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import DailyCupObjectivePopup from "../DailyCupObjectivePopup";
import { Rupee } from "@/components";

dayjs.extend(utc);

const StartGame = () => {
    const ref = useRef(null);

    const { replace } = useRouter();

    const [visible, setVisible] = useState(true);

    const [isDailyCupObjectivePopupOpen, setIsDailyCupObjectivePopupOpen] =
        useState(false);

    useEffect(() => {
        //@ts-ignore
        if (ref && ref.current && ref.current.clientHeight) {
            //@ts-ignore
            if (ref.current?.clientHeight < 128) {
                setVisible(false);
            } else {
                setVisible(true);
            }
        }
    }, [ref]);

    const { data, loading } = useGetLatestTournamentQuery();

    const { tournamentID, hasUserMadeAnEntry, level, remainingRetries } =
        useMemo(() => {
            if (data) {
                return {
                    tournamentID: data?.tournament?.[0]?.id,
                    hasUserMadeAnEntry:
                        data?.tournament?.[0]?.tournament_participants?.length >
                        0,
                    // rank:
                    //     data?.tournament?.[0]?.tournament_participants?.[0]
                    //         ?.rank ?? 0,
                    level:
                        data?.tournament?.[0]?.tournament_participants?.[0]
                            ?.current_level ?? 0,
                    remainingRetries:
                        data?.tournament?.[0]?.tournament_participants?.[0]
                            ?.remaining_retries,
                };
            }

            return {
                tournamentID: "",
                hasUserMadeAnEntry: false,
                rank: 0,
                level: 0,
                remainingRetries: 0,
            };
        }, [data]);

    const handleObjectivePopup = () => {
        setIsDailyCupObjectivePopupOpen((prev) => !prev);
    };

    const handleTournamentBanner = () => {
        if (loading) return;

        if (remainingRetries === 0) {
            replace(
                ROUTES.tournamentLeaderboard({
                    tournamentID,
                })
            );
        } else if (hasUserMadeAnEntry) {
            replace(
                ROUTES.tournamentProgress({
                    tournamentID,
                })
            );
        } else {
            handleObjectivePopup();
        }
    };

    return (
        <div className="flex w-full flex-grow flex-col items-center overflow-hidden bg-start-tournament !bg-cover !bg-center !bg-no-repeat">
            <TopBar />
            {loading ? (
                <Loader />
            ) : (
                <div
                    data-testid="tournament-banner"
                    className="flex  flex-grow flex-col items-center justify-center  overflow-hidden py-9"
                    onClick={handleTournamentBanner}
                >
                    <div className="flex flex-col items-center justify-center">
                        <p className="font-normal text-gray-4 font-swez-one text-28 ">
                            Daily League
                        </p>
                        <p className="font-bold text-dark-yellow-1 font-poppins text-2xl ">
                            Win <Rupee />
                            500 everyday!
                        </p>
                    </div>
                    <div className="relative flex-grow overflow-hidden py-6">
                        <Image
                            ref={ref}
                            src={trophyImg}
                            alt="chess-pieces"
                            className={`cursor-pointer ${
                                visible ? "block" : "hidden"
                            }`}
                            draggable="false"
                            style={{
                                objectFit: "contain",
                                maxHeight: "100%",
                            }}
                            loading="lazy"
                        />
                    </div>
                    <div className="flex flex-col items-center justify-center gap-y-2">
                        <p className="font-semibold text-gray-4 font-poppins text-base">
                            100% free-to-play
                        </p>
                        {hasUserMadeAnEntry && remainingRetries !== 0 ? (
                            <button
                                data-testid="resume-btn"
                                className={`flex flex-col whitespace-nowrap rounded bg-dark-pink-1 px-10 py-2 text-center font-extrabold text-gray-4 shadow-home-banner font-basement-grotesque text-xl`}
                            >
                                <span>Resume</span>
                                <span className="-mt-1 w-full text-center font-medium text-gray-5 font-poppins text-xs leading-18 ">
                                    Level {level}
                                </span>
                            </button>
                        ) : (
                            <button
                                data-testid="play-now-btn"
                                className={`whitespace-nowrap rounded bg-dark-pink-1 px-6 py-3 text-center font-extrabold text-gray-4 shadow-home-banner font-basement-grotesque text-xl`}
                            >
                                {remainingRetries === 0
                                    ? "See Leaderboard"
                                    : "Play Now"}
                            </button>
                        )}
                    </div>
                </div>
            )}

            {!hasUserMadeAnEntry && isDailyCupObjectivePopupOpen && (
                <DailyCupObjectivePopup
                    isOpen={isDailyCupObjectivePopupOpen}
                    handlePopup={handleObjectivePopup}
                    tournamentID={tournamentID}
                />
            )}
        </div>
    );
};

export default StartGame;
