import BottomDialog from "@/components/BottomDialog";
import React from "react";
import DailyCupImg from "@/assets/images/tournament/daily-cup-objective.png";
import Image from "next/image";
import ROUTES from "@/constants/routes";
import { useRouter } from "next/router";

const DailyCupObjectivePopup = ({
    isOpen,
    handlePopup,
    tournamentID,
}: {
    isOpen: boolean;
    handlePopup: () => void;
    tournamentID: string;
    }) => {

    const { replace } = useRouter();

    return (
        <BottomDialog open={isOpen} onClose={handlePopup}>
            <div className="flex w-full flex-col gap-y-4 bg-dark-blue-3 px-5 py-7">
                <div className="flex flex-col items-center justify-center space-y-10">
                    <h1 className="text-center font-medium text-gray-5 font-poppins text-xl">
                        Daily Cup Objective
                    </h1>
                    <Image
                        src={DailyCupImg}
                        height={146}
                        width={130}
                        alt=""
                        className=""
                    />
                    <p className="text-center font-normal text-gray-4 font-poppins text-base">
                        Move ahead of others by
                        <br />
                        reaching higher levels
                    </p>
                </div>
                <div className="flex flex-col items-center justify-center">
                    <p className="text-center font-bold text-dark-blue font-poppins text-xl">
                        <span className="font-normal text-gray-4">Win</span>{" "}
                        CASH PRIZES!
                    </p>
                    <span className="text-center font-normal text-gray-5 font-poppins text-xs">
                        as per your leaderboard rank
                    </span>
                </div>
                <button
                    onClick={()=>replace(ROUTES.tournament({ tournamentID }))}
                    className={`mt-7 w-full cursor-pointer rounded-md bg-dark-pink-1 py-2.5 text-center font-extrabold text-white outline-none font-basement-grotesque text-sm`}
                >
                    ENTER NOW
                </button>
            </div>
        </BottomDialog>
    );
};

export default DailyCupObjectivePopup;
