import { Rupee } from "@/components";
import React from "react";

const BiggerRewards = () => {
    return (
        <div
            className={`relative flex h-full min-h-35 w-full flex-col rounded-lg bg-bigger-rewards py-4 transition-all duration-500 ease-in-out`}
        >
            <div
                className={`pointer-events-none absolute bottom-0 left-0 right-0 top-0 h-full w-full bg-bigger-reward-card-back bg-no-repeat`}
                style={{ backgroundSize: "55% auto" }}
            />
            <div className="mx-auto flex h-full w-full max-w-90/100 flex-col items-stretch justify-between gap-y-4">
                <div className="relative flex w-fit flex-col">
                    <p className="font-medium text-white font-poppins text-sm">
                        Ziffi chess
                    </p>
                    <p className="font-extrabold text-orange font-basement-grotesque text-base">
                        CHAMPIONSHIP
                    </p>
                </div>
                <div className="mt-3 flex w-full items-center justify-between">
                    <div className="flex flex-col items-center justify-start">
                        <h4 className="text-center font-medium text-white font-poppins text-10">
                            PRIZE POOL
                        </h4>
                        <p className="text-center font-semibold text-white font-poppins text-sm">
                            <Rupee />
                            50,000
                        </p>
                    </div>
                    <button
                        type="button"
                        disabled={true}
                        className="cursor-not-allowed rounded-md border-2 border-dark-pink-1 bg-light-grey p-1.5 text-center font-extrabold text-gray-5 opacity-40 font-basement-grotesque text-sm"
                    >
                        Coming Soon
                    </button>
                </div>
            </div>
        </div>
    );
};

export default BiggerRewards;
